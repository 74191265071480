<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Reservations') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
          <div
            v-permission="['add-reservation']"
            class="column is-narrow"
            style="margin-left: auto"
          >
            <a-button size="large" type="primary" class="mb-2 mr-3" @click="handleExport">
              {{ $t('Export to CSV') }}
            </a-button>
            <a-button class="ant-btn ant-btn-lg ant-btn-primary" @click="handleAdd">
              {{ $t('Add New Reservation') }}
            </a-button>
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="reservations"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          row-key="id"
          @change="handleTableChange"
        >
          <template #id="{ text }">
            {{ text }}
          </template>
          <template #property="{ record }">
            {{ record.property && record.property.abbreviation }}
          </template>
          <template #roomTypeName="{ record }">
            {{ getRoomTypeName(record) }}
          </template>
          <template #roomNumber="{ record }">
            {{ getRoomNumber(record) }}
          </template>
          <template #status="{ record }">
            {{ $t(record.status) }}
          </template>
          <template #totalAccomodation="{ record }">
            {{ getTotalAccomodationCharge(record) }}
          </template>
          <template #resendAnshin="{ record }">
            <a-button
              type="primary"
              :disabled="
                isPostingAnshinReservation[record.id] ||
                  !(
                    record.property.anshinPropertyId &&
                    !record.details.reservationId
                  )"
              @click="resendAnshin(record.id)"
              @click.stop
            >
              {{ $t("Resend Anshin") }}
            </a-button>
          </template>
          <template #latestEmail="{ record }">
            {{ getSendTime(record.latestEmail) }}
            <span
              v-if="record.latestEmail"
              class="email-status"
              :class="guestEmailStatusClass(record.latestEmail)"
            >
              ({{ record.latestEmail.status }})
            </span>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n src="@/locales/components/reservations.json"></i18n>

<script>
import { saveAs } from 'file-saver';
import { getLocale } from '@/utils/locale';
import { getDefaultLang } from '@/utils/language';
import pagination from '@/components/mixins/pagination';
import SearchForm from './components/SearchForm';
import guestEmail from './mixins/guest-email';
import { cleanObject } from '@/utils/util';

export default {
  name: 'Reservations',
  components: {
    SearchForm,
  },
  mixins: [pagination, guestEmail],
  data() {
    return {
      reservations: undefined,
      isFetching: false,
      isPostingAnshinReservation: {},
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Reservation Number'),
          dataIndex: 'reservationNumber',
          key: 'reservationNumber',
          slots: { customRender: 'reservationNumber' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Room Type Name'),
          dataIndex: 'roomTypeName',
          slots: { customRender: 'roomTypeName' },
        },
        {
          title: this.$t('Room Number'),
          dataIndex: 'roomNumber',
          slots: { customRender: 'roomNumber' },
        },
        {
          title: this.$t('Status'),
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: this.$t('Check In'),
          key: 'checkin',
          dataIndex: 'checkin',
          slots: { customRender: 'checkin' },
        },
        {
          title: this.$t('Check Out'),
          key: 'checkout',
          dataIndex: 'checkout',
          slots: { customRender: 'checkout' },
        },
        {
          title: this.$t('Total Accomodation Charge'),
          key: 'totalAccomodation',
          dataIndex: 'totalAccomodation',
          slots: { customRender: 'totalAccomodation' },
        },
        {
          title: this.$t('Resend Anshin'),
          slots: { customRender: 'resendAnshin' },
        },
        {
          title: this.$t('Latest guest email'),
          key: 'latestEmail',
          dataIndex: 'latestEmail',
          slots: { customRender: 'latestEmail' },
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.isFetching = true;
      return this.$store
        .dispatch('reservations/list', this.$route.query)
        .then((res) => {
          const { list, pagination } = res;
          this.reservations = list;
          this.setPagination(pagination);
          this.isFetching = false;
        })
        .finally(() => this.$store.commit('HIDE_FULLSCREEN_LOADER'));
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$store.dispatch('routers/addFirstLevelQuery', {
            query: this.$route.query,
            name: 'reservations',
          });
          this.$router.push({ name: 'reservation-basic-info', params: { id: record.id } });
        },
      };
    },
    getRoomTypeName(record) {
      const { room, roomType } = record.reservedRoom || {};

      if (roomType) {
        return roomType.name;
      }

      return room ? room.code : '-';
    },
    getRoomNumber(record) {
      const { room, roomType, roomNumber } = record.reservedRoom || {};

      if (roomType) {
        return roomNumber || '-';
      }

      return room ? room.name : '-';
    },
    parseGuestName(record) {
      const guests = record.reservedRoom?.guests;

      if (!guests || guests.length === 0) {
        return '';
      }

      const guest = record.reservedRoom.guests[0];

      return guest.kanjiName || guest.surname;
    },
    getTotalAccomodationCharge(record) {
      return (
        (record.details
          && record.details.basicRateInfo
          && this.$filters.number(record.details.basicRateInfo.totalAccommodationCharge))
        || '-'
      );
    },
    handleExport() {
      const params = {
        ...this.$route.query,
        infinite: true,
        locale: getLocale() || getDefaultLang(),
      };
      this.$store
        .dispatch('reservations/csv', params)
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    async handleAdd() {
      const { query } = this.$route;

      await this.$router.push({
        path: '/reservations/add',
        query: cleanObject({
          propertyId: query.propertyId,
          roomTypeId: query.roomTypeId,
        }),
      });
    },
    resendAnshin(id) {
      this.isPostingAnshinReservation[id] = true;
      this.$store.dispatch('reservations/addAnshin', { id })
        .then(() => {
          this.isPostingAnshinReservation[id] = false;
          this.$message.success('Anshin reservation created');
        })
        .catch(() => {
          this.isPostingAnshinReservation[id] = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.email-status {
  display: inline-block;
  padding: 0 5px;
}

.email-status--sent {
  color: #2ac72a;
}

.email-status--failed {
  color: #ff3939;
}

.email-status--in-progress {
  color: #009ae9;
}
</style>
