<template>
  <div class="user">
    <template v-if="!loading">
      <AdminSubMenu v-if="reservation" :menu="menu" :sub-menu="subMenu">
        {{ reservation.reservationNumber }}
      </AdminSubMenu>
      <router-view />
    </template>
  </div>
</template>

<i18n>
{
  "en": {
    "Booking Info": "Booking Info",
    "Room & Guest": "Room & Guest",
    "Other Info": "Other Info",
    "Basic Info": "Basic Info",
    "Email Log": "Email Log",
  },
  "ja": {
    "Booking Info": "予約情報",
    "Room & Guest": "個々の部屋とお客様情報",
    "Other Info": "その他",
    "Basic Info": "基本情報",
    "Email Log": "Email Log",
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import previousRoute from '@/components/mixins/previousRoute';
import AdminSubMenu from '@/components/AdminSubMenu';

export default {
  name: 'Reservation',
  components: { AdminSubMenu },
  mixins: [previousRoute],
  data() {
    return {
      menuName: 'reservations',
    };
  },
  computed: {
    menu() {
      return {
        query: this.previousRouteQuery,
        name: this.menuName,
        title: this.$t('Back'),
      };
    },
    subMenu() {
      return [
        {
          name: 'reservation-basic-info',
          title: this.$t('Basic Info'),
        },
        {
          name: 'reservation-booking-info',
          title: this.$t('Booking Info'),
        },
        {
          name: 'reservation-room-guest',
          title: this.$t('Room & Guest'),
        },
        {
          name: 'reservation-other-info',
          title: this.$t('Other Info'),
        },
        {
          name: 'reservation-email-log',
          title: this.$t('Email Log'),
        },
      ];
    },
    childrenRoutes() {
      return ['basic-info', 'booking-info', 'room-guest', 'other-info', 'email-log'];
    },
    ...mapState('reservations', ['reservation', 'loading']),
  },
  created() {
    this.asyncData();
  },
  methods: {
    async asyncData(route = null) {
      route = route || this.$route;
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        this.reservation = await this.$store.dispatch('reservations/get', { id: route.params.id });
      } catch (e) {
        console.log(e);
      }
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
  },
};
</script>

<style scoped></style>
